var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Slide",class:{ showSlideshow: _vm.showSlideshow }},[_c('picture',[_vm._l((_vm.exts),function(ext){return [_vm._l((_vm.formats.sizes),function(format){return (format.orientation == 'landscape')?_c('source',{attrs:{"srcset":_vm.image + '-' + format.size + ext.ext,"media":'(orientation: ' +
            format.orientation +
            ' ) and (min-width: ' +
            format.min +
            'px) and (max-width: ' +
            format.max +
            'px)',"type":ext.mimetype}}):_vm._e()}),_vm._l((_vm.formats.sizes),function(format){return (format.orientation == 'portrait')?_c('source',{attrs:{"srcset":_vm.image + '-' + format.size + ext.ext,"media":'(orientation: ' +
            format.orientation +
            ' ) and (min-height: ' +
            format.min +
            'px) and (max-height: ' +
            format.max +
            'px)',"type":ext.mimetype}}):_vm._e()})]}),_c('img',{attrs:{"src":_vm.image + '-100.jpg',"alt":_vm.item.title},on:{"load":function($event){return _vm.slideLoaded()}}})],2),_c('div',{staticClass:"SlideCredit",class:{ SlideCreditWhite: _vm.color }},[_c('span',[_vm._v(_vm._s(_vm.item.photographer))])])])}
var staticRenderFns = []

export { render, staticRenderFns }