import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import qs from 'qs';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageData: null,
    pageSlides: null,
    pageList: null,
    pageListMeta: null,
    hideFooter: false,
    pageRoutes: null,
    showNavigation: false,
    showDropdown: false,
    openDropdown: false,
    loadedTitle: false,
    loadedSlideshow: false,
  },
  mutations: {
    setPageData(state, payload) {
      if (payload.route_options) {
        payload.options = JSON.parse(payload.route_options);
        delete payload.route_options;
      }
      state.pageData = payload;
    },
    setPageSlides(state, payload) {
      state.pageSlides = payload;
    },
    setPageList(state, payload) {
      if (payload) state.pageList = payload.content;
      else state.pageList = null;
      if (payload) state.pageListMeta = payload.meta;
      else state.pageListMeta = null;
    },
    pushPageList(state, payload) {
      if (payload.content) {
        payload.content.forEach(item => state.pageList.push(item));
        state.pageListMeta = payload.meta;
      } else {
        state.hideFooter = false;
        state.pageListMeta = payload.meta;
      }
    },
    setPageRoutes(state, payload) {
      payload.forEach((page, index) => {
        payload[index].options = JSON.parse(page.route_options);
        delete payload[index].route_options;
      });
      state.pageRoutes = payload;
    },
  },
  getters: {
    pageData: state => state.pageData,
    pageList: state => state.pageList,
    pageListMeta: state => state.pageListMeta,
    pageRoutes: state => state.pageRoutes,
    pageSlides: state => state.pageSlides,
    hideFooter: state => state.hideFooter,
    showNavigation: state => state.showNavigation,
    loadedSlideshow: state => state.loadedSlideshow,
    windowDimensions: state => state.windowDimensions,
  },
  actions: {
    // FETCH ITEMS
    async fetchItems({ commit, state }, payload) {
      if (!payload.action) commit(payload.target, null);

      await axios
        .post('/api/' + payload.table + '/', qs.stringify(payload.query))
        .then(res => {
          if (!payload.action) {
            commit(payload.target, res.data);
          } else {
            commit('pushPageList', res.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
  modules: {},
});
