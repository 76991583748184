import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import qs from "qs";
import store from "../store/";
Vue.use(VueRouter);
const defaultComponent = Vue.component("button-counter", {
  template: "test",
});

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  let table = "pages";
  let query = { id: to.matched[0].props.default.id };
  //let query = { slug: to.path };
  store.state.hideFooter = false;

  // ARTWORKS
  if (to.name == "artworks-details") {
    table = "artworks";
    query = { q: { slug: to.params.slug } };
  }

  // PRESS
  if (to.name == "press-details") {
    table = "press";
    query = { q: { slug: to.params.slug } };
  }

  // FETCH PAGE DATA
  axios
    .post("/api/" + table + "/", qs.stringify(query))
    .then((res) => {
      if (res.data.content) {
        if (!res.data.content[0].route_options) {
          res.data.content[0].route_options = JSON.stringify(
            store.state.pageRoutes.filter(
              (route) => route.route_name == to.name.split("-")[0]
            )[0].options
          );
        }
        store.commit("setPageData", res.data.content[0]);
        store.state.showNavigation = false;
        store.state.showDropdown = false;
        store.state.openDropdown = false;
        store.state.loadedSlideshow = false;
        store.state.loadedTitle = false;
        next();
      } else {
        next("/404/");
      }
    })
    .catch((err) => {
      console.log(err);
    });
});

export default router;
