<template lang="html">
  <footer>
    <div class="NaviGrid">
      <div></div>
      <ul v-for="route in showRoutes(1)" class="main">
        <li>{{ route.title }}</li>

        <li v-for="subroute in subRoutes(route.id)">
          <router-link :to="{ path: subroute.route }" :title="subroute.title">
            {{ subroute.title }}
          </router-link>
        </li>
      </ul>

      <div class="Else">
        <ul>
          <li v-for="route in showRoutes(3)">
            <router-link :to="{ name: route.route_name }" :title="route.title">
              {{ route.title }}
            </router-link>
          </li>
        </ul>
        <Social />
      </div>
    </div>

    <div class="LogoGrid">
      <Logo />
      <div class="Copyright">
        <strong>&copy; Christo and Jeanne-Claude Foundation</strong> All images
        on this website are copyrighted.
        <router-link :to="{ name: 'privacy', params: {} }" class="link"
          >Privacy Policy</router-link
        >
      </div>
      <Social />
    </div>
  </footer>
</template>

<script>
import Social from '@/components/Elements/Social.vue';
import Logo from '@/components/Elements/Logo.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Social,
    Logo,
  },
  computed: {
    ...mapGetters(['pageRoutes']),
  },
  methods: {
    showRoutes(selector) {
      let items = null;
      if (selector == 1) {
        items = this.pageRoutes.filter(route => route.is_menu_top == 1);
      }

      if (selector == 3) {
        items = this.pageRoutes.filter(route => route.is_menu_top == 2);
      }

      items.sort((a, b) =>
        a.is_order > b.is_order ? 1 : b.is_order > a.is_order ? -1 : 0,
      );
      return items;
    },
    subRoutes(id) {
      const items = this.pageRoutes.filter(route => route.is_menu_sub == id);
      items.sort((a, b) =>
        a.is_menu_top > b.is_menu_top
          ? 1
          : b.is_menu_top > a.is_menu_top
          ? -1
          : 0,
      );
      return items;
    },
  },
};
</script>
<style>
footer {
  background: #222929;
  padding: 12px;
  margin-top: 48px;
}

footer .link {
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 1px;
}

footer .NaviGrid {
  display: none;
}

footer .LogoGrid {
  margin-top: 6px;
}

footer .Logo img {
  max-width: 400px;
}

footer .Copyright {
  color: white;
  font-size: 0.55rem;
  line-height: 1.1rem;
  margin-top: 6px;
  margin-bottom: 36px;
}

footer .LogoGrid .Social {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: min-content min-content min-content;
}

footer .Social img {
  width: 30px;
  height: 30px;
}

@media only screen and (min-width: 600px) {
  footer {
    padding: 24px;
    margin-top: 48px;
  }

  footer .Copyright {
    font-size: 0.6rem;
    margin-bottom: 36px;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 768px) {
  footer {
    margin-top: 72px;
  }

  footer .NaviGrid {
    display: grid !important;
    width: 100%;
    padding: 0 !important;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    user-select: none;
  }

  footer .NaviGrid .main {
    display: block;
    white-space: nowrap;
    grid-row-start: 1;
  }

  footer .NaviGrid .main li {
    display: block;
    /* font-size: 0.75rem;
    line-height: 1.5rem; */
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    width: calc(100% + 20px);
  }

  footer .LogoGrid .Social {
    display: none;
  }

  footer .Social {
    margin-top: 6px !important;
  }

  footer .Social img {
    width: 27px !important;
    height: 27px !important;
    filter: invert(0) !important;
  }

  footer .NaviGrid ul li:first-child {
    cursor: auto;
    color: white;
  }

  footer a {
    color: white !important;
  }

  footer .Else ul li:last-child {
    border-bottom: none !important;
  }

  footer .NaviGrid .Else {
    grid-template-rows: min-content auto;
  }

  footer .LogoGrid {
    margin-top: 48px;
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: 1fr min-content;
    justify-content: end;
    align-items: end;
  }

  footer .Copyright {
    font-size: 0.6rem;
    margin-bottom: 3px;
    white-space: nowrap;
    user-select: none;
  }
}

@media only screen and (min-width: 900px) {
  footer {
    padding: 24px 36px;
    margin-top: 90px;
  }

  footer .LogoGrid {
    margin-top: 72px;
  }

  footer .Copyright {
    font-size: 0.7rem;
  }

  footer .LogoGrid img {
    max-width: 720px;
    width: calc(50%);
  }

  footer .Social img {
    width: 30px !important;
    height: 30px !important;
    filter: invert(0) !important;
  }
}
</style>
