<template lang="html">
  <nav
    v-if="pageRoutes && pageData"
    class="NaviGrid NavigationSticky"
    :class="{
      NavigationStartup: loadedSlideshow,
      showNavigation: showNavigation,
    }"
  >
    <div class="Head" v-if="!footer">
      <Logo />
      <Close />
    </div>

    <ul v-for="route in showRoutes(1)" v-if="!showSubMenu" class="main">
      <li :title="route.title" @click="toggleNavigation(route.id)">
        {{ route.title }}
      </li>

      <li v-for="subroute in subRoutes(route.id)">
        <router-link
          :to="{ path: subroute.route }"
          :title="subroute.title"
          @click.native="toggleNavigation()"
        >
          {{ subroute.title }}
        </router-link>
      </li>
    </ul>

    <ul v-if="showSubMenu" class="subMenu">
      <li v-for="route in showRoutes(2)">
        <router-link
          :to="{ path: route.route }"
          :title="route.title"
          @click.native="toggleNavigation()"
        >
          {{ route.title }}
        </router-link>
      </li>
      <li class="black" @click="showSubMenu = 0">Back</li>
    </ul>

    <div class="Else">
      <ul v-if="!showSubMenu">
        <li v-for="route in showRoutes(3)">
          <router-link
            :to="{ name: route.route_name }"
            :title="route.title"
            @click.native="toggleNavigation()"
          >
            {{ route.title }}
          </router-link>
        </li>
      </ul>
      <Social color="black" />
    </div>
  </nav>
</template>

<script>
import Logo from '@/components/Elements/Logo.vue';
import Social from '@/components/Elements/Social.vue';
import Close from '@/components/Elements/NavigationClose.vue';
import stylesNavigation from '@/assets/css/navigation.css';
import { mapGetters } from 'vuex';

export default {
  components: {
    Logo,
    Close,
    Social,
  },
  props: {
    footer: null,
  },
  data() {
    return {
      showSubMenu: null,
      nav: document.querySelector('nav'),
      windowWidth: window.innerWidth,
      timeout: null,
    };
  },
  computed: {
    ...mapGetters([
      'pageRoutes',
      'pageData',
      'showNavigation',
      'loadedSlideshow',
    ]),
  },
  methods: {
    toggleNavigation(id) {
      if (!id && this.windowWidth < 768) {
        this.$store.state.showNavigation = false;
        setTimeout(this.resetNavigation, 1200);
        return;
      }
      if (this.windowWidth < 768) {
        this.showSubMenu = id;
      } else {
        this.nav.classList.toggle('showNavigation');
      }
    },
    resetNavigation() {
      this.showSubMenu = false;
    },
    showRoutes(selector) {
      let items = null;
      if (selector == 1) {
        items = this.pageRoutes.filter(route => route.is_menu_top == 1);
      }

      if (selector == 2) {
        items = this.pageRoutes.filter(
          route => route.is_menu_sub == this.showSubMenu,
        );
      }

      if (selector == 3) {
        items = this.pageRoutes.filter(route => route.is_menu_top == 2);
      }

      items.sort((a, b) =>
        a.is_order > b.is_order ? 1 : b.is_order > a.is_order ? -1 : 0,
      );
      return items;
    },
    subRoutes(id) {
      const items = this.pageRoutes.filter(route => route.is_menu_sub == id);
      items.sort((a, b) =>
        a.is_menu_top > b.is_menu_top
          ? 1
          : b.is_menu_top > a.is_menu_top
          ? -1
          : 0,
      );
      return items;
    },
    // OBSERVER FOR STICKY BOTTOMNAV
    createObserver() {
      const header = document.querySelector('.Header');
      this.nav.classList.add('NavigationFixed');

      let options = {
        threshold: 1,
      };

      let observer = new IntersectionObserver(this.headerObserver, options);
      observer.observe(header);
    },
    // HEADER OBSERVER
    headerObserver(e) {
      if (e[0].isIntersecting == true) {
        this.nav.classList.remove('NavigationSticky');
        this.nav.classList.add('NavigationFixed');
      }

      if (e[0].isIntersecting == false) {
        this.nav.classList.add('NavigationSticky');
        this.nav.classList.remove('NavigationFixed');
      }

      //  else {
      //   console.log('bottom');
      //   // this.nav.classList.add('NavigationFixed');
      //   this.nav.classList.remove('NavigationSticky');
      // }
    },
    // RESIZE FUNCTION
    onResize() {
      this.$store.state.showNavigation = false;
      this.nav.style.transition = 'none';
      this.nav.style.display = 'none';
      this.showSubMenu = false;
      this.windowWidth = window.innerWidth;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.showNav, 500);
    },
    showNav() {
      if (window.innerWidth < 768) {
        this.nav.style.display = 'grid';
        this.nav.style.opacity = '1';
        this.nav.style.left = '100%';
        this.nav.style.transition = 'left 1s';
      } else {
        this.nav.style.left = '0';
        // this.nav.style.display = 'grid';
        this.nav.style.transition = 'bottom 1s 1s, padding 1s';
        this.nav.style.bottom = '0';
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.nav = document.querySelector('nav');
    });
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    // WAIT FOR SLIDESHOW
    loadedSlideshow() {
      this.createObserver();
      this.showNav();
    },
    showNavigation() {
      // if (window.innerWidth < 768) {
      //   if (this.showNavigation == true) {
      //     document.querySelector('html').style.overflow = 'hidden';
      //     document.querySelector('body').style.overflow = 'hidden';
      //   } else {
      //     document.querySelector('html').style.overflow = 'auto';
      //     document.querySelector('body').style.overflow = 'auto';
      //   }
      // }
    },
  },
};
</script>
