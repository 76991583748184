<template lang="html">
  <div class="Slideshow">
    <div class="Slide" v-if="!video">
      <div class="SlideCredit" :class="{ SlideCreditWhite: color }">
        <span>{{ slides[0].photographer }}</span>
      </div>
    </div>

    <transition-group
      mode="out-in"
      name="fade"
      v-if="!video && slides.length > 1"
    >
      <Slide
        v-for="item in slides"
        v-show="item.id == currentImg.id"
        :key="item.id"
        :item="item"
        :color="color"
      />
    </transition-group>
    <Slide
      v-if="!video && slides.length == 1"
      :key="slides[0].id"
      :item="slides[0]"
      :color="color"
    />

    <video
      v-if="video"
      playsinline
      loop
      muted
      :title="video.title"
      ref="video"
      :key="video.id"
    >
      <source :src="'/src/' + video.file" />
    </video>
  </div>
</template>

<script>
import stylesSlideShow from "../../assets/css/slideshow.css";
import Slide from "@/components/Slideshow/SlideshowSlide.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Slide,
  },
  props: {
    color: null,
  },
  data() {
    return {
      timer: null,
      currentIndex: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(["pageData"]),
    slides() {
      const isMobile = this.windowWidth <= this.windowHeight ? 1 : 0;
      let slides = this.pageData._slideshow.filter(
        (slide) => slide.is_mobile == isMobile
      );

      slides = slides.filter((slide) => slide.mimetype?.includes("image"));
      return slides;
    },
    video() {
      let videos = this.pageData._slideshow.filter((slide) =>
        slide.mimetype?.includes("video/mp4")
      );

      if (this.windowWidth > 400) {
        let output;

        const byWidth = videos.slice(0);
        byWidth.sort(function(a, b) {
          return a.meta_width - b.meta_width;
        });

        output = videos[0];
        videos.forEach((video) => {
          if (this.windowWidth > output.meta_width) output = video;
        });

        return output;
      } else return false;
    },
    currentImg: function() {
      return this.slides[Math.abs(this.currentIndex) % this.slides.length];
    },
  },
  methods: {
    ...mapActions(["fetchItems"]),
    startSlide: function() {
      this.timer = setInterval(this.next, 5000);
    },
    init() {
      // this.$store.state.loadedSlideshow = true;
      // setTimeout(() => {
      //   this.loaded = true;
      // }, 1400);
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      this.loaded = true;
      //this.showPreview = false;
    },
    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
    onResize() {
      this.$store.state.showNavigation = false;
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);

      if (this.video) {
        this.init();
        this.$refs.video.autoplay = true;
      } else {
        this.startSlide();
      }
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* .hidePreview {
  opacity: 0;
  transition: opacity 0.3s 1s;
}

.showPreview {
  opacity: 1;
} */

video {
  position: absolute;
  object-fit: cover;
  border-radius: 1px;
  width: 100%;
  height: 100%;
  opacity: 1;
}
</style>
