import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

// VUE META
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

// FETCH ROUTES
let req = new FormData();
req.append('o', 'is_order');
req.append('l', -1);
axios
  .post('/api/routes/', req)
  .then(res => {
    store.state.pageData = null;
    store.commit('setPageRoutes', res.data.content);
    for (const page of res.data.content) {
      var newRoute = {
        name: page.route_name,
        path: page.route,
        component: () => import('./views/' + page.route_view),
        pathToRegexpOptions: { strict: true },
        props: {
          id: page.id,
        },
        meta: {
          title: process.env.VUE_APP_TITLE + ' - ' + page.title,
        },
      };

      if (page.route) router.addRoutes([newRoute]);
    }

    init();
  })
  .catch(err => {
    console.log(err);
  });

// INIT APP AFTER ROUTE FETCH
function init() {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
}
