<template lang="html">
  <div v-if="pageData" id="app">
    <div class="Page" :class="{ PageSlideLeft: showNavigation }">
      <Header :key="'Header_' + $route.fullPath" />
      <Dropdown v-if="pageData.options.dropdown" />
      <Navigation />
      <ScrollTop />
      <main>
        <router-view :key="$route.fullPath" />
      </main>

      <Footer v-if="!hideFooter" :class="{ hideFooter: $route.path == '/' }" />
      <Cookie />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Elements/Navigation.vue';
import Header from '@/components/Elements/Header.vue';
import Dropdown from '@/components/Elements/Dropdown.vue';
import Footer from '@/components/Elements/Footer.vue';
import Cookie from '@/components/Elements/Cookie.vue';
import ScrollTop from '@/components/Elements/ScrollTop.vue';

import stylesMain from './assets/css/main.css';
import stylesMedia from './assets/css/media.css';
import stylesADT from '@/assets/css/adt.css';

import { mapGetters } from 'vuex';

export default {
  components: {
    Navigation,
    Header,
    Footer,
    Cookie,
    Dropdown,
    ScrollTop,
  },
  computed: {
    ...mapGetters(['showNavigation', 'pageData', 'hideFooter']),
  },
  metaInfo() {
    return {
      title: 'Christo and Jeanne-Claude',
      titleTemplate: this.pageData ? '%s | ' + this.pageData.title : '',
      meta: [
        {
          name: 'description',
          content: this.pageData ? this.pageData.meta_description : '',
        },
        {
          name: 'keywords',
          content: this.pageData ? this.pageData.meta_keywords : '',
        },
      ],
    };
  },
  mounted() {
    // HANDLE LINKS
    window.addEventListener('click', event => {
      let { target } = event;
      if (target && target.href) {
        const url = new URL(target.href);
        const to = url.host;

        if (to != window.location.host) {
          if (target.matches("a:not([href*='mailto:'])")) {
            event.preventDefault();
            window.open(url, '_blank').focus();
          }
        }
      }
    });
  },
};
</script>

<style>
@media only screen and (min-width: 600px) {
  .hideFooter {
    display: none;
  }
}
</style>
