<template lang="html">
  <div class="Social" :class="{ invert: color }">
    <a
      href="https://www.facebook.com//ChristoandJC"
      target="top"
      title="Facebook"
    >
      <img
        src="@/assets/img/icons/SocialFacebook.png"
        alt="Facebook"
        width="30"
        height="30"
      />
    </a>
    <a
      href="https://instagram.com/christojeanneclaude"
      target="top"
      title="Instagram"
      width="30"
      height="30"
    >
      <img
        src="@/assets/img/icons/SocialInstagram.png"
        alt="Instagram"
        width="30"
        height="30"
      />
    </a>
    <a href="https://twitter.com/ChristoandJC" target="top" title="Twitter">
      <img
        src="@/assets/img/icons/SocialTwitter.png"
        alt="Twitter"
        width="30"
        height="30"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    color: null,
  },
};
</script>
