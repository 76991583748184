<template lang="html">
  <div class="Logo">
    <router-link :to="{ name: 'home', params: {} }">
      <img
        v-if="color == 0"
        src="@/assets/img/signature-black.png"
        alt="Christo and Jeanne-Claude Logo"
        width="450"
        height="59"
      />
      <img
        v-else
        src="@/assets/img/signature-white.png"
        alt="Christo and
      Jeanne-Claude Logo"
        width="450"
        height="59"
      />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {
    color: null,
  },
  computed: {
    ...mapGetters(['pageData']),
  },
};
</script>

<style lang="css" scoped>
.Logo {
  user-select: none;
}

.Logo img {
  width: 100%;
  height: auto;
  max-width: 450px;
}
</style>
