<template lang="html">
  <div class="Slide" :class="{ showSlideshow: showSlideshow }">
    <picture>
      <template v-for="ext in exts">
        <source
          v-for="format in formats.sizes"
          :srcset="image + '-' + format.size + ext.ext"
          :media="
            '(orientation: ' +
              format.orientation +
              ' ) and (min-width: ' +
              format.min +
              'px) and (max-width: ' +
              format.max +
              'px)'
          "
          :type="ext.mimetype"
          v-if="format.orientation == 'landscape'"
        />

        <source
          v-for="format in formats.sizes"
          :srcset="image + '-' + format.size + ext.ext"
          :media="
            '(orientation: ' +
              format.orientation +
              ' ) and (min-height: ' +
              format.min +
              'px) and (max-height: ' +
              format.max +
              'px)'
          "
          :type="ext.mimetype"
          v-if="format.orientation == 'portrait'"
        />
      </template>

      <img :src="image + '-100.jpg'" :alt="item.title" @load="slideLoaded()" />
    </picture>

    <div class="SlideCredit" :class="{ SlideCreditWhite: color }">
      <span>{{ item.photographer }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    item: null,
    color: null,
  },
  data() {
    return {
      showSlideshow: false,
      exts: [
        { ext: '.webp', mimetype: 'image/webp' },
        { ext: '.jpg', mimetype: 'image/jpeg' },
      ],
      formats: {
        sizes: [
          { size: '400', orientation: 'landscape', min: 200, max: 400 },
          { size: '800', orientation: 'landscape', min: 400, max: 800 },
          { size: '1200', orientation: 'landscape', min: 800, max: 1200 },
          { size: '1600', orientation: 'landscape', min: 1200, max: 1600 },
          { size: '2800', orientation: 'landscape', min: 1600, max: 9000 },
          { size: '800h', orientation: 'portrait', min: 200, max: 800 },
          { size: '1200h', orientation: 'portrait', min: 800, max: 3200 },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['pageData']),
    image() {
      return '/src/' + this.item.image.split('-')[0];
    },
  },
  methods: {
    slideLoaded() {
      this.showSlideshow = true;
      this.$store.state.loadedSlideshow = true;
    },
  },
  created() {},
};
</script>
