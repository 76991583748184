<template lang="html">
  <div
    v-if="pageData"
    class="Dropdown"
    :class="{
      showDropdown: $store.state.showDropdown,
      PageSlideLeft: showNavigation,
    }"
  >
    <div class="DropdownSelect">
      <div class="Entry" @click="toggleContent()">
        {{ pageData.options.dropdown.title }}
      </div>

      <div class="DropdownTrigger">
        <img
          v-if="!$store.state.openDropdown"
          src="@/assets/img/icons/ChevronDown.png"
          @click="toggleContent()"
          alt="Open Dropdown"
        />
        <img
          v-else
          src="@/assets/img/icons/ChevronUp.png"
          alt="Close Dropdown"
          @click="toggleContent()"
        />
      </div>

      <div class="pointer">
        <img
          src="@/assets/img/icons/MenuTriggerBlack.png"
          alt="Trigger Dropdown"
          @click="toggleNavigation()"
        />
      </div>
    </div>
    <div v-if="$store.state.openDropdown" class="DropdownContent">
      <div v-for="link in sortedLinks" class="Entry">
        <router-link
          :to="{ name: link.route, params: {} }"
          class="EntryLink"
          v-if="link.route"
        >
          {{ link.title }}
        </router-link>

        <router-link :to="{ path: link.path }" class="EntryLink" v-else>
          {{ link.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import stylesDropdown from '@/assets/css/dropdown.css';

import { mapGetters } from 'vuex';

export default {
  props: {},
  computed: {
    ...mapGetters(['pageData', 'showNavigation']),
    pageContainer() {
      return document.querySelector('.Page');
    },
    sortedLinks() {
      return this.pageData.options.dropdown.links.filter(
        link => link.route != this.$route.name,
      );
    },
    loadedTitle() {
      return this.$store.state.loadedTitle;
    },
  },
  watch: {
    loadedTitle() {
      if (this.loadedTitle == true) {
        this.createObserver();
      }
    },
  },
  mounted() {},
  methods: {
    toggleNavigation() {
      this.$store.state.showNavigation = !this.$store.state.showNavigation;
    },
    createObserver() {
      const title = document.querySelector('.Title');
      let options = {
        root: null,
        threshold: 0.1,
      };

      let observer = new IntersectionObserver(this.toggleDropdown, options);
      observer.observe(title);
    },
    toggleDropdown(e) {
      const intersecting = e[0].isIntersecting;
      const scrollPos = window.scrollY;
      const windowHeight = window.innerHeight;
      if (intersecting == false && scrollPos > windowHeight) {
        this.$store.state.showDropdown = true;
      }
      if (intersecting == true && scrollPos > windowHeight) {
        this.$store.state.showDropdown = false;
        this.$store.state.openDropdown = false;
      }
    },
    toggleContent() {
      this.$store.state.openDropdown = +!this.$store.state.openDropdown;
    },
  },
};
</script>
